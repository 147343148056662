@charset "utf-8";

/* reset
-------------------*/

html {
  background-color: $white;
  font-size: 62.5%;
  /* sets the base font to 10px for easier math */
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
p,
img {
  margin: 0;
  padding: 0;
}

body {
  @include notosans;
  color: $black;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include fontSize;
  line-height: 1;
  letter-spacing: 1px;
  padding-top: 0px;
  background-repeat: repeat;
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  @include pc {
    padding-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include gothic;
    font-weight: 600;
  }
}

a {
  color: $gray03;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  @include transition;
  &:link,
  &:hover,
  &:focus {
    color: $gray03;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    @include transition;
  }
  &:hover {
    text-decoration: underline;
  }
}

ul,
ol {
  list-style-type: none;
}

::selection {
  background-color: rgba($accent, 0.7);
  color: $white;
  /* Safari */
}

::-moz-selection {
  background-color: rgba($accent, 0.7);
  color: $white;
  /* Firefox */
}

#contents p {
  line-height: 1.7;
}
