
        .iconList {
            width: 100%;
            overflow: hidden;
            padding: 4em;

            li {
                float: left;
                width: 32%;
                text-align: center;
                border: 1px solid $bdrGray;
                padding: 3em 3%;

                +li {
                    margin-left: 2%;

                    h3 {
                        span {
                            i {
                                font-size: 1.8em;
                            }
                        }
                    }
                }

                h3 {
                    font-size: 1.6em;
                    letter-spacing: 0.2em;
                    @include mincho;

                    span {
                        display: table;
                        table-layout: fixed;
                        width: 100%;
                        margin-bottom: 1em;

                        i {
                            font-size: 2.6em;
                            display: table-cell;
                            vertical-align: middle;
                            color: $gray06;
                        }
                    }

                    &::after {
                        font-size: 0.6em;
                        font-weight: 500;
                        content: attr(data-text);
                        display: block;
                        margin-top: 1em;
                        color: $gray09;
                    }
                }

                .bscBtn01 {
                    margin-top: 2em;
                    a{
                        background-color: $bdrGray;
                        color: $black;
                            @media (max-width:1360px){
                            padding: .8em 4em;
                            }
                            @media (max-width:1120px){
                            padding: .8em 3em;
                            }
                        &:hover{
                            background-color: $c01;
                            color: $white;
                        }
                    }
                }
            }
            @include pc {
                text-align: center;
                padding: 15px 0;
                li {
                    width: 47%;
                    float: none;
                    display: inline-block;
                    margin: 0 1%;
                    + li + li {
                        margin-top: 3%;
                    }
                }
            }

            @include tab {
                padding: 0;
                margin-top: 1em;
                li{
                    display: block;
                    width: 100%;
                    margin: 0;
                    + li{
                        margin: 1em 0 0 0;
                    }
                }
            }
        }