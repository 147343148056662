//section
#contents {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
}

section {
    clear: both;
    padding: 60px 0;
    margin-bottom: 0;

    &.last {
        margin-bottom: 0;
    }
}

.scInr {
    margin: 0 auto;
    max-width: 1240px;
    width: 97%;
}

.scInrS {
    margin: 0 auto;
    max-width: 1000px;
    width: 97%;
}

.scInrSS {
    margin: 0 auto;
    max-width: 880px;
    width: 97%;
}

.scInrW {
    margin: 0 auto;
    max-width: 1366px;
    width: 97%;
}

.scInrWW {
    margin: 0 auto;
    max-width: 1550px;
    width: 97%;
}

.is-pc {
    display: block !important;

    @include tab {
        display: none !important;
    }
}

.is-sp {
    display: none !important;

    @include tab {
        display: block !important;
    }
}

.spTel {
    pointer-events: none;
}

//width
.w5 {
    width: 5% !important;
}

.w10 {
    width: 10% !important;
}

.w15 {
    width: 15% !important;
}

.w18 {
    width: 18% !important;
}

.w20 {
    width: 20% !important;
}

.w25 {
    width: 25% !important;
}

.w30 {
    width: 30% !important;
}

.w35 {
    width: 35% !important;
}

.w40 {
    width: 40% !important;
}

.w45 {
    width: 45% !important;
}

.w50 {
    width: 50% !important;
}

.w55 {
    width: 55% !important;
}

.w60 {
    width: 60% !important;
}

.w65 {
    width: 65% !important;
}

.w70 {
    width: 70% !important;
}

.w75 {
    width: 75% !important;
}

.w80 {
    width: 80% !important;
}

.w85 {
    width: 85% !important;
}

.w90 {
    width: 90% !important;
}

.w95 {
    width: 95% !important;
}

.w100 {
    width: 100px;
}

.w200 {
    width: 200px;
}

.w300 {
    width: 300px;
}

.w400 {
    width: 400px;
}

.w450 {
    width: 450px;
}

.w500 {
    width: 500px;
}

.w600 {
    width: 600px;
}

.wFull {
    width: 100% !important;
}

//float
.fltImgL {
    float: left;
    margin: 0 25px 20px 0;
}

.fltImgR {
    float: right;
    margin: 0 0 20px 25px;
}

.fltL {
    float: left;
}

.fltR {
    float: right;
}

.cf {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    zoom: 1;
}

br.clear,
.clear {
    display: inline !important;
    display: none;
    clear: both;
    line-height: 0px;
}

br.clear {
    clear: both;
    line-height: 0px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    br.clear {
        display: block !important;
        content: " ";
    }
}

@include pc {
    section {
        padding: 30px 0;
    }

    .scInr,
    .scInrS,
    .scInrW {
        float: none;
        margin: 0 3%;
        width: auto;
    }
    .fltL,
    .fltR {
        float: none;
    }

    .fltImgL,
    .fltImgR {
        float: none;
        margin: 0;
        text-align: center;
    }

    .fltImgL img,
    .fltImgR img {
        max-width: 100%;
        margin-bottom: 5px;
    }
}