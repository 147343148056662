@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?mn5l4b");
  src: url("fonts/icomoon.eot?mn5l4b#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?mn5l4b") format("truetype"), url("fonts/icomoon.woff?mn5l4b") format("woff"),
    url("fonts/icomoon.svg?mn5l4b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\ea92";
}
.icon-heart01:before {
  content: "\e900";
}
.icon-arrow_d:before {
  content: "\e90b";
}
.icon-arrow_u:before {
  content: "\e90c";
}
.icon-arrow_r:before {
  content: "\e90d";
}
.icon-arrow_l:before {
  content: "\e90e";
}
.icon-uniE90F:before {
  content: "\e90f";
}
.icon-uniE910:before {
  content: "\e910";
}
.icon-recruit:before {
  content: "\e918";
}
.icon-01:before {
  content: "\e919";
}
.head01 {
  font-size: 24px;
  @include Montserrat;
  font-weight: 400;
}

.head02 {
  @include Montserrat;
  font-size: 45px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 3px;
  margin-bottom: 30px;
}

.head03 {
  text-align: center;
  @include Montserrat;
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 3px;
  position: relative;
  margin-bottom: 50px;
  &::after {
    content: attr(data-text);
    color: #000;
    display: block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.15em;
    margin-top: 0.5em;
  }
}

.head04 {
  font-size: 20px;
  margin: 1em 0;
  text-align: center;
  @include Montserrat;
}

.head05 {
  font-size: 26px;
  position: relative;
  padding-left: 25px;
  padding-bottom: 10px;
  border-bottom: 2px solid #dadfdf;
  margin-bottom: 30px;
  &::before {
    content: "";
    @include posCenterY;
    top: 40%;
    left: 0;
    display: block;
    width: 0.6em;
    height: 0.6em;
    background-color: $c01;
  }
}
.head06 {
  font-size: 24px;
  color: $c01;
  margin: 1em 0;
  @include Montserrat;
}

.head07 {
  font-size: 20px;
  position: relative;
  padding-left: 35px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  &::before {
    content: "";
    @include posCenterY;
    top: 40%;
    left: 0;
    display: block;
    width: 18px;
    height: 2px;
    background-color: $c01;
  }
}

@include sp {
  .head01 {
    @include fontSizeVw(24);
  }

  .head02 {
    @include fontSizeVw(32);
  }

  .head03 {
    @include fontSizeVw(32);
  }
}

/* indent */
.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.top0 {
  margin-top: 0 !important;
}

.top5 {
  margin-top: 5px !important;
}

.top10 {
  margin-top: 10px !important;
}

.top15 {
  margin-top: 15px !important;
}

.top20 {
  margin-top: 20px !important;
}

.top25 {
  margin-top: 25px !important;
}

.top30 {
  margin-top: 30px !important;
}

.top35 {
  margin-top: 35px !important;
}

.top40 {
  margin-top: 40px !important;
}

.btm0 {
  margin-bottom: 0 !important;
}

.btm5 {
  margin-bottom: 5px !important;
}

.btm10 {
  margin-bottom: 10px !important;
}

.btm15 {
  margin-bottom: 15px !important;
}

.btm20 {
  margin-bottom: 20px !important;
}

.btm25 {
  margin-bottom: 25px !important;
}

.btm30 {
  margin-bottom: 30px !important;
}

.btm35 {
  margin-bottom: 35px !important;
}

.btm40 {
  margin-bottom: 40px !important;
}

.btm45 {
  margin-bottom: 45px !important;
}

.btm50 {
  margin-bottom: 50px !important;
}

.btm55 {
  margin-bottom: 55px !important;
}

.btm60 {
  margin-bottom: 60px !important;
}

.btm65 {
  margin-bottom: 65px !important;
}

.btm70 {
  margin-bottom: 70px !important;
}

.btm75 {
  margin-bottom: 75px !important;
}

.btm80 {
  margin-bottom: 80px !important;
}

.btm85 {
  margin-bottom: 85px !important;
}

.btm90 {
  margin-bottom: 90px !important;
}

.pT0 {
  padding-top: 0 !important;
}

.pT5 {
  padding-top: 5px !important;
}

.pT10 {
  padding-top: 10px !important;
}

.pT15 {
  padding-top: 15px !important;
}

.pT20 {
  padding-top: 20px !important;
}

.pT25 {
  padding-top: 25px !important;
}

.pT30 {
  padding-top: 30px !important;
}

.pT35 {
  padding-top: 35px !important;
}

.pT40 {
  padding-top: 40px !important;
}

.pT45 {
  padding-top: 45px !important;
}

.pB0 {
  padding-bottom: 0 !important;
}

.pB5 {
  padding-bottom: 5px !important;
}

.pB10 {
  padding-bottom: 10px !important;
}

.pB15 {
  padding-bottom: 15px !important;
}

.pB20 {
  padding-bottom: 20px !important;
}

.lm5 {
  margin-left: 5px !important;
}

.lm10 {
  margin-left: 10px !important;
}

.lm15 {
  margin-left: 15px !important;
}

.lm20 {
  margin-left: 20px !important;
}

.lm50 {
  margin-left: 50px !important;
}

.lm60 {
  margin-left: 60px !important;
}

.lm70 {
  margin-left: 70px !important;
}

.rm5 {
  margin-right: 5px !important;
}

.rm10 {
  margin-right: 10px !important;
}

.rm15 {
  margin-right: 15px !important;
}

.rm20 {
  margin-right: 20px !important;
}

.vAT {
  vertical-align: top !important;
}

.vAM {
  vertical-align: middle !important;
}

.vAB {
  vertical-align: bottom !important;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

// size

.size10 {
  font-size: 1rem !important;
}

.size11 {
  font-size: 1.1rem !important;
}

.size12 {
  font-size: 1.2rem !important;
}

.size13 {
  font-size: 1.3rem !important;
}

.size14 {
  font-size: 1.4rem !important;
}

.size15 {
  font-size: 1.5rem !important;
}

.size16 {
  font-size: 1.6rem !important;
}

.size17 {
  font-size: 1.7rem !important;
}

.size18 {
  font-size: 1.8rem !important;
}

.size19 {
  font-size: 1.9rem !important;
}

.size20 {
  font-size: 2rem !important;
}

.size21 {
  font-size: 2.1rem !important;
}

.size22 {
  font-size: 2.2rem !important;
}

.size23 {
  font-size: 2.3rem !important;
}

.size24 {
  font-size: 2.4rem !important;
}
