#mv {
  background-position: center center;
  background-size: cover;
  width: 100%;
  .mvInr {
    margin: 0 auto;
    max-width: 1200px;
    width: 97%;
    position: relative;
  }
}

#subMv {
  .mvInr {
    background-attachment: fixed;
    height: 98vh;
    background-position: center center;
    background-size: cover;
    max-width: 100%;
    width: 98%;
    margin: 0 auto;
  }

  @include pc {
    .mvInr {
      height: 100vh;
      width: 100%;
      background-attachment: initial;
    }
  }
}

#breadCrumbs {
  @include transition;
  margin: 0 auto;
  padding: 10px 5px;
  font-size: 0.92em;
  background-color: #eaeaea;
  @include gothic;
  color: #7b7474;

  a {
    display: inline-block;
    text-decoration: none;
    font-size: 0.92em;
    @include gothic;
    color: #7b7474;
  }

  i {
    font-size: 120%;
    vertical-align: text-top;
  }
}

#main {
  .mainInr {
    background-color: #fff;
    padding: 3em;

    @include pc {
      padding: 5% 3%;
    }
  }

  .detailInfo {
    padding-bottom: 1em;
    margin-bottom: 3em;

    time {
      font-size: 1.3em;
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
      margin-bottom: 30px;
      @include Montserrat;
    }

    span {
      &.cat {
        padding-top: 0.7em;
        font-size: 0.8em;
        display: inline-block;
        padding: 0.7em 1em;
        background-color: $catClr01;
        margin-left: 10px;
        margin-bottom: 30px;
        vertical-align: middle;
        &02 {
          background-color: $c01;
          color: #fff;
        }
      }
    }
    a {
      @include gothic;
      font-size: 0.9em;
      display: inline-block;
      padding: 0.7em 1em;
      background-color: $catClr01;
      color: #fff;
      margin: 0 0.5em 0.5em 0;
      text-decoration: none;
      margin-right: 2em;

      &:hover {
        color: #fff;
        opacity: 0.7;
      }

      &02 {
        background-color: $catClr02;
      }

      &03 {
        background-color: $catClr03;
      }
    }

    h1 {
      font-size: 1.55em;
      line-height: 1.4;
      @include mincho;
      margin-top: 0.3em;
    }

    h2 {
      font-size: 24px;
    }

    @include sp {
      margin-bottom: 1.5em;

      .cat {
        font-size: 2.8vw;
      }

      h1 {
        font-size: 4.5vw;
      }
    }
  }
}

#main {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 4em;

  .topicsList {
    overflow: hidden;

    article {
      float: left;
      width: 31%;
      margin-right: 3.5%;
      position: relative;
      @include transition;
      + article + article + article {
        margin-top: 3em;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }

      .img {
        overflow: hidden;
        margin-bottom: 1em;
        line-height: 0;
        position: relative;
        z-index: 1;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          @include transition;
          object-fit: cover;
        }

        &::after {
          @include imgOverlay;
        }
      }

      h3 {
        line-height: 1.4;
        font-size: 1.5rem;
        margin-bottom: 0.8em;
        text-decoration: none;
      }

      p {
        margin-bottom: 1em;
        line-height: 1.4;
        text-decoration: none;
      }

      time {
        display: block;
        margin-bottom: 0.8em;
        color: #656565;
      }

      a {
        text-decoration: none;
      }

      .cat {
        padding-top: 0.7em;
        border-top: 1px solid #bcbcbc;

        a {
          font-size: 0.8em;
          display: inline-block;
          padding: 0.7em 1em;
          background-color: #fff;
          border: 1px solid #bcbcbc;
          margin: 0 0.5em 0.5em 0;
          text-decoration: none;

          &:hover {
            color: #fff;
            background-color: $c01;
            border-color: $c01;
          }
        }
      }

      &:hover {
        transform: translateY(-10px);

        .img {
          img {
            transform: scale(1.1);
          }

          &::after {
            background-color: rgba($black, 0.3);
          }
        }
      }
    }
  }
}

.plantBox {
  li {
    float: left;
    width: calc(100% / 3);
    .imgInr {
      overflow: hidden;
    }
    .img {
      background-size: cover;
      background-position: center center;
      height: 300px;
      overflow: hidden;
      z-index: 1;
      position: relative;
      @include transition;

      &:after {
        content: "";
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include transition;
      }
    }
    .txt {
      text-align: center;
      padding: 20px 0;
      font-weight: 600;
      border-right: $c02 solid 1px;
    }
    &:hover {
      .img {
        transform: scale(1.1);
        &:after {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      a {
        text-decoration: none;
      }
      @include tab {
        .img {
          transform: scale(1);
          &:after {
            display: none;
          }
        }
        a {
          text-decoration: none;
        }
      }
    }
    &:nth-child(1) {
      .img {
        background-image: url(/lib/cmn_img/plant01.jpg);
      }
    }
    &:nth-child(2) {
      .img {
        background-image: url(/lib/cmn_img/plant02.jpg);
      }
    }
    &:nth-child(3) {
      border-right: none;
      .img {
        background-image: url(/lib/cmn_img/plant03.jpg);
      }
    }
    &:nth-child(4) {
      .img {
        background-image: url(/lib/cmn_img/plant04.jpg);
      }
    }
    &:nth-child(5) {
      .img {
        background-image: url(/lib/cmn_img/plant05.jpg);
      }
    }
    &:nth-child(6) {
      border-right: none;
      .img {
        background-image: url(/lib/cmn_img/plant06.jpg);
      }
    }
  }
  @include pc {
    li {
      width: calc(100% / 2);
    }
  }
  @include tab {
    .scroll {
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      &::after {
        content: "※フリックで横スクロールができます。";
        display: block;
        font-size: 1em;
        margin: 1em;
        margin-bottom: 2em;
      }
    }
    .scrollInr {
      display: inline-block;
      white-space: nowrap;
    }
    li {
      float: none;
      display: inline-block;
      width: 35%;
    }
  }
}

.newsTab {
  li {
    text-align: center;
    &:nth-child(3) {
      a {
        margin-right: 0;
      }
    }
    a {
      margin-right: 2%;
      border: 1px solid #000;
      float: left;
      width: calc(96% / 3);
      font-size: 1em;
      padding: 1.5em 0;
      color: #000;
      @include transition;
    }
    &:hover {
      a {
        border: 1px solid $c01;
        background-color: $c01;
        color: #fff;
        text-decoration: none;
        letter-spacing: 3px;
      }
    }
    .active {
      border: 1px solid $c01;
      background-color: $c01;
      color: #fff;
      text-decoration: none;
      letter-spacing: 3px;
    }
  }
  @include tab {
    li {
      &:first-child {
        a {
          margin-right: 0;
          width: 100%;
          margin-bottom: 2em;
        }
      }
      &:nth-child(2) {
        a {
          width: 49%;
        }
      }
      &:nth-child(3) {
        a {
          width: 49%;
        }
      }
      &:hover {
        a {
          border: 1px solid #000;
          background-color: transparent;
          color: #000;
          text-decoration: none;
          letter-spacing: 1px;
        }
      }
      &:hover {
        .active {
          border: 1px solid $c01;
          background-color: $c01;
          color: #fff;
          text-decoration: none;
          letter-spacing: 3px;
        }
      }
    }
  }
}

.newsList {
  margin-top: 3em;
  li {
    padding: 1em 0;
    padding-left: 2em;
    @include tab {
      padding-left: 0;
    }
    border-top: #dadfdf 1px solid;
    &:last-child {
      border-bottom: #dadfdf 1px solid;
    }

    dl {
      @include transition;
      display: table;
      table-layout: fixed;
      width: 100%;
      &:hover {
        transform: translateX(1em);
        @include tab {
          transform: translateX(0);
        }
      }

      dt {
        display: table-cell;
        vertical-align: middle;
        margin-right: 2em;
        font-size: 0.9em;
        width: 220px;

        .cat {
          display: inline-block;
          background-color: $catClr01;
          width: 80px;
          padding: 0.6em 0.5em 0.6em;
          color: #000;
          text-align: center;
          margin-left: 2em;
          text-decoration: none;
          vertical-align: middle;

          &:hover {
            opacity: 0.7;
          }

          &02 {
            background-color: $c01;
            color: #fff;
          }

          &03 {
            background-color: $catClr03;
          }
        }

        time {
          font-size: 1.3em;
          display: inline-block;
          vertical-align: middle;
          font-weight: 400;
          @include Montserrat;
        }
      }

      dd {
        display: table-cell;
        vertical-align: middle;
        line-height: 1.7;
        @include notosans;
        a {
          display: block;
          font-weight: 600;
          position: relative;
          i {
            position: absolute;
            right: 1%;
            top: 25%;
            @include tab {
              right: 2%;
              top: -30px;
            }
          }
        }
      }
    }
  }
  @include pc {
    li {
      dl {
        dt,
        dd {
          vertical-align: top;
        }
      }
    }
  }
  @include tab {
    li {
      dl {
        display: block;

        dt {
          display: block;
        }

        dd {
          display: block;
          margin-top: 1em;
        }
      }
    }
  }
}

.otherBox {
  .otherList {
    li {
      float: left;
      width: calc(100% / 3);
      .imgInr {
        overflow: hidden;
      }
      .img {
        background-size: cover;
        background-position: center center;
        height: 300px;
        overflow: hidden;
        z-index: 1;
        position: relative;
        @include transition;

        &:after {
          content: "";
          height: 100%;
          width: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          @include transition;
        }
      }
      .txt {
        text-align: center;
        padding: 20px 0;
        font-weight: 600;
      }
      &:hover {
        .img {
          transform: scale(1.1);
          &:after {
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        a {
          text-decoration: none;
        }
      }
      &:nth-child(1) {
        .img {
          background-image: url(/lib/cmn_img/other01.jpg);
        }
      }
      &:nth-child(2) {
        .img {
          background-image: url(/lib/cmn_img/other02.jpg);
        }
      }
      &:nth-child(3) {
        border-right: none;
        .img {
          background-image: url(/lib/cmn_img/other03.jpg);
        }
      }
    }
  }
  @include pc {
    .otherList {
      text-align: center;
      li {
        float: none;
        display: inline-block;
        width: calc(100% / 2);
        &:nth-child(3) {
          width: 50%;
          margin: 0 auto;
        }
        .img {
          height: 250px;
        }
      }
    }
  }
  @include tab {
    .otherList {
      li {
        float: none;
        width: 100%;
        &:nth-child(3) {
          width: 100%;
        }
        .img {
          height: 200px;
        }
        .txt {
          font-size: 18px;
        }
        &:hover {
          .img {
            transform: scale(1);
            &:after {
              display: none;
            }
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.clm3Box {
  overflow: hidden;

  li {
    float: left;
    margin-bottom: 30px;
    margin-right: 2%;
    width: calc(96% / 3);

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include pc {
      width: calc(96% / 2);

      &:nth-child(3n) {
        margin-right: 2%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @include tab {
    margin-bottom: 30px;

    ul {
      width: 800%;

      li {
        width: 6%;
        margin: 0;
        margin-right: 15px;
        margin-bottom: 25px;

        &:nth-child(2n) {
          margin-right: 15px;
        }

        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }

  @include sp {
    ul {
      width: 800%;

      li {
        width: 10%;
        margin: 0;
        margin-right: 15px;
        margin-bottom: 25px;

        &:nth-child(2n) {
          margin-right: 15px;
        }

        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
}

.bscBox01 {
  box-sizing: border-box;
  padding: 30px;
  color: $c01;
  background-color: #fff;
}

.bscBox02 {
  background-color: #fff;
  box-sizing: border-box;
  padding: 2.5em;
  border: 4px solid $c02;
}

.indList,
.indListS {
  margin-bottom: 15px;

  li::before {
    content: "●";
    font-size: 50%;
    margin-right: 5px;
    vertical-align: middle;
    color: $c01;
  }
}

.indList li {
  line-height: 170%;
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 5px;

  @include sp {
    font-size: 3.2vw;
  }
}

.indListS li {
  line-height: 170%;
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 5px;
  font-size: 1.1rem !important;
  line-height: 130%;
}

.numList,
.numListS {
  margin-bottom: 15px;
  padding-left: 1.6em;
}

.numList li {
  line-height: 150%;
  list-style: decimal outside none;
  margin-bottom: 5px;

  @include sp {
    font-size: 3.2vw;
  }
}

.numListS li {
  line-height: 150%;
  list-style: decimal outside none;
  margin-bottom: 5px;
  font-size: 1.1rem !important;
  line-height: 130%;
}

.bscTbl01 table {
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid #fff;
  }

  th,
  td {
    font-size: 1em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.4;
    padding: 20px 20px 17px;
    text-align: left;
    vertical-align: top;
  }

  th {
    background: $c01;
    color: $c02;
    border: none;
    line-height: 1.2;
    width: 30%;
    @include pc {
      padding: 1em 1em;
      font-size: 1.3em;
      width: 100%;
    }
  }

  td {
    background: $c02;
  }

  tr.head th {
    background-color: $c02;
    color: #fff;
  }
}

.bscTbl01 + .bscTbl01 {
  margin-top: 3.5em;
}

.bscTbl01.tblScroll {
  table {
    table-layout: fixed;

    th,
    td {
      display: table-cell;
      width: auto;
    }
  }
}

@include tab {
  .tblScroll {
    -webkit-overflow-scrolling: touch;
    &::after {
      content: "※フリックで横スクロールができます。";
      display: block;
      font-size: 1em;
      margin-top: 1em;
    }

    .tblScrollInr {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
      }
    }

    table {
      margin-bottom: 0;
      width: 250%;
      table-layout: fixed;

      th,
      td {
        display: table-cell;
        vertical-align: top;
      }
    }
  }
}

.clm4 {
  overflow: hidden;

  li {
    float: left;
    margin-right: 1.3%;
    width: 24%;

    &:nth-child(4n) {
      margin-right: 0;
    }

    + li + li + li + li {
      margin-top: 2em;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

.clm3 {
  overflow: hidden;

  li {
    float: left;
    margin-bottom: 20px;
    margin-right: 1.3%;
    width: 32.4%;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

.clm2 {
  overflow: hidden;

  li {
    float: left;
    margin-bottom: 20px;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

//pager
.pager {
  margin-top: 60px;
  text-align: center;
  font-size: 0;
  letter-spacing: -0.4em;

  a {
    color: #000;
    border: 1px solid $c02;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    padding: 1.2em 7em;
    text-decoration: none;
    position: relative;
    font-size: 14px;
    letter-spacing: normal;
    @include transition;
    @include Montserrat;
    font-weight: 400;
    letter-spacing: 3px;

    i {
      @include posCenterY;
      @include transition;
    }

    &.prev,
    &.next {
      &:hover {
        background-color: $c01;
        text-decoration: none;
        color: #fff;
        i:before {
          color: #fff;
        }
      }
    }

    &.prev {
      background-color: transparent;
      border-right: 1px $c02 solid;

      i {
        left: 5%;
      }

      &:hover {
        i {
          left: 3%;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      color: #9e9e9e;

      i:before {
        color: #9e9e9e;
      }
    }

    &.next {
      background-color: transparent;
      border-left: none;

      i {
        right: 5%;
      }

      &:hover {
        i {
          right: 3%;
        }
      }
    }
  }

  @include tab {
    padding: 0 3%;

    a {
      width: 50%;
      font-size: 1.1rem;
    }

    @include sp {
      a {
        padding: 1.2em 0em;
        font-size: 2.8vw;

        &.prev,
        &.next {
          margin: 0;
        }
      }
    }
  }
}

.btnList {
  margin-top: 6em;
  margin-bottom: 8em;

  @include pc {
    margin-top: 4em;
    margin-bottom: 6em;
  }

  @include tab {
    margin-top: 3em;
    margin-bottom: 5em;
  }

  li {
    .btn {
      float: left;
      width: 25%;
      text-align: center;

      @include tab {
        width: 30%;
      }

      a {
        @include transition;
        @include Montserrat;
        color: #000;
        background-color: #fff;
        border: 1px solid $c02;
        display: block;
        padding: 1.2em 0em;
        text-decoration: none;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 3px;

        @include sp {
          font-size: 11px;
        }

        i {
          @include posCenterY;
          @include transition;
        }

        &:hover {
          background-color: $c01;
          text-decoration: none;
          color: #fff;

          i:before {
            color: #fff;
          }

          @include tab {
            background-color: transparent;
            text-decoration: none;
            color: #000;

            i:before {
              color: #000;
            }
          }
        }

        &.prev {
          border-right: none;

          i {
            left: 5%;
          }

          &:hover {
            i {
              left: 3%;
            }
          }
        }

        &.next {
          border-left: none;

          i {
            right: 5%;
          }

          &:hover {
            i {
              right: 3%;
            }
          }
        }
        &.disabled {
          pointer-events: none;
          color: #9e9e9e;

          i:before {
            color: #9e9e9e;
          }
        }
      }
    }

    &:nth-child(2) {
      .btn {
        float: left;
        width: 50%;

        @include tab {
          width: 40%;
        }
      }
    }
  }
}

#topcontrol {
  display: none;
  z-index: 900;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: translateZ(0);

  a {
    background-color: $c01;
    display: block;
    height: 60px;
    width: 60px;
    filter: alpha(opacity=70) !important;
    opacity: 0.7 !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
      filter: alpha(opacity=10) !important;
      opacity: 1 !important;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }

    &::after {
      content: "";
      display: block;
      transform: rotate(-45deg);
      color: #fff;
      width: 12px;
      height: 12px;
      border-top: 4px solid #fff;
      border-right: 4px solid #fff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 26px;
      left: 22px;
      border-radius: 2px;
    }
  }
  @include pc {
    bottom: 10px !important;
    right: 10px !important;
    height: 40px;
    width: 40px;
    a {
      width: 40px;
      height: 40px;
      &::after {
        top: 18px;
        left: 14px;
        border-width: 2px;
      }
    }
  }
}
.bscTbl01 {
  &.contactForm {
    .leadCauText {
      text-align: center;
      margin-top: 4em;
      @include gothic;

      &::before {
        content: "※";
        color: $ctRed;
      }
    }

    table {
      border: none;
      margin: 0 auto;

      tbody {
        tr {
          border-bottom: 1px solid #fff;
        }

        th,
        td {
          font-size: 1.1em;
          line-height: 1.4;
        }

        th {
          background: $c01;
          color: $c02;
          border: none;
          padding: 0em 1em 0em 3em;
          line-height: 1.2;
          vertical-align: middle;
          width: 30%;

          @include pc {
            padding: 1em 1em;
            font-size: 1.3em;
            width: 100%;
          }
        }

        td {
          background: $c02;
          border: none;
          padding: 2em 1em;
          @include gothic;
          vertical-align: middle;

          input[type="text"],
          textarea {
            appearance: none;
            background-color: transparent;
            border: 2px solid transparent;
            transition: all 0.3s $easeInOutSine;
            color: #fff;

            &:focus {
              outline: none;
              border: 2px solid rgba(#fff, 0.8) !important;
              background-color: inherit !important;
              border-radius: 3px;
            }
          }

          textarea {
            border: 2px solid #fff;
          }

          &.nc {
            input,
            textarea {
              &.empty {
                border-radius: 3px;
              }
            }

            select {
              &.empty {
                border-radius: 3px;
              }
            }
          }

          input[type="text"] {
            padding: 0.7em 3px 0.5em;
            border-bottom: 1px #fff solid;
          }

          @include pc {
            padding: 2em 1em;

            .inputClm2 {
              li {
                input {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.subscribeBtn {
  text-align: center;
  max-width: 100%;
  margin: 3em auto 0;
  text-align: center;

  p {
    background-color: $c01;
    border: none;
    color: #fff;
    display: inline-block;
    width: 100%;

    + p {
      margin-left: 1.3%;
    }

    @include tab {
      float: none;
      width: 100%;

      + p {
        margin-left: 0;
        margin-top: 1em;
      }
    }

    @include transition;
    position: relative;

    i {
      display: inline-block;
      font-size: 0.8em;
      @include posCenterY;
      right: 2%;
      &::before {
        color: #fff;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }

    &.return {
      background-color: #dedede;
      color: $black;

      input {
        color: $black;
      }

      i {
        color: $black;
        left: 15%;
        right: auto;
      }
    }

    input {
      display: block;
      width: 100%;
      background: none;
      border: none;
      color: #fff;
      padding: 1.2em 0 1.2em;
      line-height: 140%;
      @include transition;
      text-decoration: none;
      margin-top: 0;
    }
  }
}

input {
  &.textFocus {
    color: #333;
  }

  &.wSS,
  &.wS,
  &.wM,
  &.wL,
  &.wLL,
  &.wFull {
    // background-color: #FCFBF8;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 1.3rem;
    line-height: 140%;
    padding: 8px 10px;

    &:focus {
      background-color: #efefef;
    }
  }

  vertical-align: middle;
}

.wSS {
  width: 85px;
}

.wS {
  width: 170px;
}

.wM {
  width: 270px;
}

.wL {
  width: 470px;
}

.wLL {
  width: 80%;
}

.wFull {
  width: 100%;
}

.imeOn {
  ime-mode: active;
}

.imeOff {
  ime-mode: disabled;
}

textarea.txtarea {
  background-color: #fcfbf8;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  height: 200px;
  padding: 10px;
  width: 100%;
}

option {
  font-size: 1.4rem !important;
}

label {
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    margin-right: 3px;
  }
}

.formErr {
  color: #e54e52;
  font-weight: bold;
}

select.slctArea {
  background-color: #fcfbf8;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  padding: 8px;

  &[multiple="multiple"] {
    option {
      background-color: transparent !important;
    }
  }
}

input.inputErr,
textarea.inputErr {
  background-color: #fadfde;
}

input:focus,
select:focus,
textarea:focus {
  background-color: #eee;
}

.req {
  display: inline-block;
  background-color: #fff;
  color: $c01;
  @include gothic;
  font-size: 1.1rem;
  padding: 0.4em 0.8em;
  margin-left: 3px;
  margin-right: 5px;
  vertical-align: middle;
}

.agreeBtn {
  margin-top: 1em;
}

.errText {
  box-sizing: border-box;
  color: $c01;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 1em;

  + .errText {
    margin-top: 0;
  }
}

.cauText {
  color: #333;
  font-size: 0.85em;
  line-height: 1.4 !important;
  margin-top: 1em;
}

.inputClm2 {
  li {
    display: inline-block;
    float: none;
    width: auto;
    margin-bottom: 0;

    &:nth-child(2) {
      float: none;
      margin-left: 1em;
    }

    span,
    input {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      margin-right: 1em;
    }
  }

  @include pc {
    li {
      display: block;
      width: 100%;

      span {
        display: block;
        margin-bottom: 1em;
        width: auto;
        margin-right: 0;
      }

      input {
        width: 100%;
      }

      &:nth-child(2) {
        margin-top: 1em;
        margin-left: 0;
      }
    }
  }
}

.cBoxList {
  li {
    display: inline-block;

    + li {
      margin-left: 1.5em;
    }

    @include tab {
      display: block;
      margin-top: 1em;

      + li {
        margin-left: 0;
      }
    }

    label {
      position: relative;
      margin: 0.5em;
      cursor: pointer;

      input {
        position: relative;
        margin: 0 1rem 0 0;
        cursor: pointer;

        &::before {
          position: absolute;
          z-index: 1;
          top: 0.125rem;
          left: 0.1875rem;
          width: 0.75rem;
          height: 0.375rem;
          content: "";
          transition: transform 0.2s cubic-bezier(0.45, 1.8, 0.5, 0.75);
          transform: rotate(-45deg) scale(0, 0);
          border: 2px solid $c01;
          border-top-style: none;
          border-right-style: none;
        }

        &:checked::before {
          -webkit-transform: rotate(-45deg) scale(1, 1);
          transform: rotate(-45deg) scale(1, 1);
        }

        &::after {
          position: absolute;
          top: -0.125rem;
          left: 0;
          width: 1rem;
          height: 1rem;
          content: "";
          cursor: pointer;
          border: 2px solid #cccccc;
          background: #ffffff;
        }
      }
    }
  }

  + .cBoxList {
    margin-top: 1.5em;

    @include tab {
      margin-top: 1em;
    }
  }
}

.formAddressArea {
  p:not(.first) {
    margin-top: 1em;
  }

  .selectBox {
    margin-top: 1em;
  }
}

.selectBox {
  overflow: hidden;
  max-width: 470px;
  width: 100%;
  text-align: center;
  position: relative;
  // border: 2px solid transparent;
  background: #efefef;

  select {
    width: 100%;
    padding: 0.7em 1em 0.5em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid transparent;
    @include transition;

    &::-ms-expand {
      display: none;
    }
  }

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid $c02;
    pointer-events: none;
    @include posCenterY;
    right: 15px;
  }
}

.privacyCheckBox {
  margin-top: 3em;
  p {
    font-size: 14px;
  }

  .privacyTxtBox {
    background: $c02;
    border: 1px solid $c02;
    padding: 3em;
    margin-top: 1em;

    .txt {
      color: #000;
      font-size: 12px;
      height: 180px;
      overflow-y: scroll;
    }
  }
}

@include pc {
  #breadCrumbs {
    display: none;
  }

  /*contents*/
  #contents {
    margin: 0 auto;
    // padding-top: 40px;
    width: 100%;
  }

  #main {
    float: none;
    width: 100%;

    .topicsList {
      article {
        width: 48.5%;
        margin: 0;

        &:nth-child(2n) {
          float: right;
        }

        + article + article {
          margin-top: 3em;
        }

        @include sp {
          width: 100%;
          float: none;

          &:nth-child(2n) {
            float: none;
          }

          + article {
            margin-top: 2em;
          }
        }
      }
    }
  }

  .bscBox01,
  .bscBox02 {
    padding: 5%;
  }

  .bscTbl01 {
    th,
    td {
      display: block;
      // font-size: 1.1rem;
      padding: 10px 3%;
      width: 100%;
    }

    &.scroll {
      th,
      td {
        display: table-cell;
      }
    }
  }

  .clm4 li {
    float: left;
    margin-right: 0;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }

    + li + li {
      margin-top: 2em;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .clm3 li {
    float: left;
    margin-right: 0;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }
  }

  .pager {
    text-align: center;

    @include tab {
      a {
        font-size: 1.1rem;
      }

      @include sp {
        a {
          font-size: 2.8vw;
          padding: 0.7em 0.6em 0.7em;

          &.prev,
          &.next {
            margin: 0;
          }
        }
      }
    }
  }

  .subscribeBtn input {
    padding: 15px 0 13px;
    margin-top: 5px;
    width: 100%;
  }

  input {
    &.wSS,
    &.wS,
    &.wM,
    &.wL,
    &.wLL {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .wSS {
    width: 30%;
  }

  .wS {
    width: 50%;
  }

  .wM,
  .wL,
  .wLL {
    width: 100%;
  }

  .imeOn {
    ime-mode: active;
  }

  .imeOff {
    ime-mode: disabled;
  }

  textarea.txtarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 200px;
    width: 100%;
  }

  select.timearea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
  }

  option {
    font-size: 1.4rem !important;
  }

  label {
    cursor: pointer;

    &:hover {
      color: #999;
    }
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      margin-right: 3px;
    }
  }

  .formErr {
    color: #e54e52;
    font-weight: bold;
  }

  input.inputErr,
  textarea.inputErr {
    background-color: #fadfde;
  }

  .req {
    // color: #FFF;
    font-size: 10px;
    line-height: 100%;
    margin-left: 3px;
    padding: 2px 5px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .cauText {
    display: block;
  }

  //END
}
