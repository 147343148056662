#header,
#headerTop {
  position: fixed;
  z-index: 600;
  top: 0;
  width: 100%;
  transform: translate(0);
  @include transition;
  height: 100px;
  overflow: hidden;
  background-color: $white;
  .logo {
    @include posCenterY;
    width: 230px;
    left: 30px;
    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .contact {
    @include posCenterY;
    @include transition;
    left: auto;
    right: 30px;
    text-align: center;
    a {
      background-color: $c01;
      border: 1px solid $c01;
      padding: 20px 0;
      color: #fff;
      font-weight: 600;
      width: 200px;
      display: block;
      @include transition;
      @media (max-width: 1180px) {
        width: 140px;
      }
    }
    &:hover {
      a {
        background-color: #fff;
        color: $c01;
        text-decoration: none;
      }
    }
  }
  #gNav {
    height: 60px;
    @include posCenter;
    ul {
      li {
        position: relative;
        i {
          position: absolute;
          right: -3px;
          &.icon {
            right: -15px;
          }
        }
      }
    }
  }
}

#headerTop {
  position: absolute;
  top: auto;
  bottom: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  &.fixed {
    position: fixed;
    top: 0;
  }
}

#gNav {
  position: relative;
  height: 100px;
  ul {
    li {
      display: inline-block;
      a {
        padding: 1.4em 1.4em;
        @media (max-width: 1180px) {
          padding: 1.4em 1em;
        }
        display: block;
        font-size: 1.1em;
        text-decoration: none;
        span {
          position: relative;
          &::after {
            position: absolute;
            bottom: -4px;
            left: 0;
            content: "";
            width: 100%;
            height: 1px;
            background: #333;
            transform: scale(0, 1);
            transform-origin: center top;
            transition: transform 0.3s;
          }
        }
        &:hover {
          span {
            &::after {
              transform: scale(1, 1);
            }
          }
        }
      }
    }
  }
}

#spHeader,
.spMenu {
  display: none;
}

#spNav,
#spFixed {
  display: none;
}

@include pc {
  #header,
  #headerTop,
  #gNav {
    display: none;
  }

  #spHeader {
    background-color: #fff;
    height: 60px;
    padding: 7px 0;
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 980;
    @include transition;

    .logo {
      a {
        display: block;
        max-width: 100%;
        width: 180px;
        padding-left: 5px;
        @include sp {
          width: 170px;
          padding-top: 2px;
        }
      }
    }
    .instagram {
      cursor: pointer;
      display: block;
      height: 60px;
      width: 60px;
      position: fixed;
      right: 60px;
      top: 0;
      z-index: 1010;
      text-align: center;
      padding-top: 7px;
      a {
        font-size: 45px;
        &:hover {
          text-decoration: none;
        }
        i {
          &::before {
            color: $c01;
          }
        }
      }
    }
  }

  .spMenu {
    // background: red;
    cursor: pointer;
    display: block;
    height: 60px;
    width: 60px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1010;

    span {
      background-color: $c01;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      /* Fix for IE7 */
      *display: inline;
      /* Fix for IE7 */
      height: 2px;
      width: 34px;
      position: absolute;
      right: 13px;
      @include transition;
    }

    span {
      &:nth-of-type(1) {
        top: 18px;
      }

      &:nth-of-type(2) {
        top: 28px;
      }

      &:nth-of-type(3) {
        top: 38px;
      }
    }

    &.active {
      height: 40px;
      width: 40px;

      span {
        background-color: $c01;

        &:nth-of-type(1) {
          -webkit-transform: translateY(10px) rotate(-45deg);
          transform: translateY(10px) rotate(-45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          -webkit-transform: translateY(-10px) rotate(45deg);
          transform: translateY(-10px) rotate(45deg);
        }
      }

      small {
        color: #000;
      }
    }
  }

  /*spNav*/
  #spNav {
    height: 100%;
    display: block;
    @include transition;
    visibility: hidden;
    padding-top: 59px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    transform: translateY(5%);
    background-color: $white;
    opacity: 0;

    &.active {
      visibility: visible;
      opacity: 1;
      top: 0;
      transform: translateY(0);
    }

    #spBg {
      // background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 500%;
      position: fixed;
      right: -50%;
      top: 0;
      z-index: -1;
      opacity: 0;
      @include transition;
      pointer-events: none;
    }

    &.active #spBg {
      right: 0;
      opacity: 1;
      pointer-events: auto;
    }

    &::after {
      // background: rgba(255, 255, 255, 0.8);
      background-color: #fff;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &::before,
    &::after {
      opacity: 0;
      @include transition;
    }

    &.active {
      &::before,
      &::after {
        opacity: 1;
      }
    }

    .closeBtn {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 15px 3%;
      text-align: right;
    }

    nav {
      @include transition;
      height: calc(100vh - (59px));
      overflow-y: scroll;

      ul {
        position: static;

        li {
          float: none;
          width: 100%;
          float: none;
          margin: 0;

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            appearance: none;
            width: 100%;
          }

          a,
          span,
          button {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            color: $c01;
            @include Montserrat;

            @include sp {
              @include fontSizeVw(13);
            }

            font-weight: 300;
            text-align: center;
            display: block;
            padding: 20px 10px;
            position: relative;
          }

          a i,
          span i,
          button i {
            font-size: 1.3em;
            color: $c01;
            text-align: right;
            margin: auto;
            position: absolute;
            right: 5%;
            top: 50%;
            margin-top: -0.5em;
            z-index: 2;
          }

          a:hover {
            background-color: rgba(0, 115, 200, 0.8);
            color: #fff;
            text-decoration: none;

            &::before {
              color: $white;
            }
          }

          .subNav {
            display: none;

            li {
              border-bottom: none;

              a {
                font-size: 0.8em;
                padding: 14px 2em 12px 1.5em;
              }
            }
          }

          &.sub {
            span {
              position: relative;

              &::before,
              &::after {
                content: "";
                display: block;
                width: 9px;
                border-bottom: 1px solid $c01; // background-color: $c01;
                position: absolute;
                right: 6.3%;
                @include posCenterY;
                @include transition;
              }

              &::after {
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
              }

              &.active {
                &::after {
                  -webkit-transform: rotate(0);
                  -moz-transform: rotate(0);
                  -ms-transform: rotate(0);
                  -o-transform: rotate(0);
                  transform: rotate(0);
                }
              }
            }
          }
        }
      }
    }
  }
}
