#sideNav {
    float: right;
    width: 300px;

    h3 {
        background-color: $c02;
        @include gothic02;
        letter-spacing: 0.2em;
        color: #FFF;
        display: block;
        padding: 1.3em 0;
        text-align: center;
    }

    dl {
        width: 100%;
        border: 1px solid #b0b0b0;
        margin-bottom: 1.6em;
        margin-top: 1em;

        +dl {
            margin-top: 0;
        }

        dt {
            font-size: 2.0rem;
            font-weight: 600;
            position: relative;
            padding: 1em 0.7em;
            background-color: #fff;
            cursor: pointer;

            .arrow {
                display: block;
                width: 50px;
                height: 30px;
                background-color: #cecece;
                @include posCenterY;
                @include transition;
                right: 0;

                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-bottom: 1px solid #fff;
                    border-right: 1px solid #fff;
                    @include transition;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 18px;
                    top: 4px;
                }
            }

            &.active {
                &::before {
                    left: -8px;
                }

                .arrow {
                    background-color: #aaaaaa;

                    &::after {
                        transform: rotate(-135deg);
                        top: 12px;
                    }
                }
            }

        }

        dd {
            width: 100%;
            display: none;
            background-color: #fff;

            ul {
                li {
                    width: 90%;
                    margin: 0 auto 0;

                    a {
                        display: block;
                        padding: 1.3em 0;
                        color: $c01;
                        font-weight: 600;
                        font-size: 1.5rem;

                        span {
                            font-size: 0.85em;
                            color: #000;
                        }
                    }

                    +li {
                        border-top: 1px solid #d6d6d6;
                    }
                }
            }

            &.active {
                display: block;
            }
        }
    }
}

@include pc {
        #sideNav {
        float: none;
        margin: 3em 0 0;
        max-width: 100%;
        width: auto;
    }
}