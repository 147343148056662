@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:500,700");
@import url("https://fonts.googleapis.com/css?family=Muli");
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP");

$pc: 1024px;
$tab: 768px;
$sp: 480px;
$defaultFontSize-px: 14px;
$defaultFontSize-rem: 1.5em;
$ie11: "_:-ms-lang(x)::-ms-backdrop";

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin ie11 {
  _:-ms-lang(x)::-ms-backdrop {
    @content;
  }
}

@mixin fontSizeVw($size) {
  $winW: 480;
  font-size: ($size / $winW) * 100 + vw;
}

@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin fontSize {
  font-size: $defaultFontSize-px;
  font-size: $defaultFontSize-rem;

  @include sp {
    @include fontSizeVw(16);
  }
}

@mixin transition {
  transition: all 0.3s $easeInOutQuad;
}

@mixin transition02 {
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

@mixin transition03 {
  transition: all 0.3s ease-in-out;
}

@mixin gothic {
  font-family: YuGothic, "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, メイリオ, verdana,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin gothic02 {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", Meiryo, メイリオ, verdana,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin notosans {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin Montserrat {
  font-family: "Montserrat", sans-serif;
}

@mixin mincho {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", Garamond, "Times New Roman",
    "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@mixin uniqFont {
  font-family: "Muli", sans-serif;
}

@mixin athelas {
  font-family: "athelas", serif;
  font-style: normal;
  font-weight: 400;
}
