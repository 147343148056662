.bscBtn01 {
  text-align: center;
  a {
    display: inline-block;
    @include bscBtn;
    border: 1px solid #fff;
    background-color: $c01;
    font-size: 1.2em;
    padding: 0.8em 4em 0.9em;
    position: relative;
    &:hover {
      background-color: $c01;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 12px;
      border-bottom: 1px solid #fff; // background-color: $c01;
      position: absolute;
      right: 13%;
      @include posCenterY;
      @include transition;
    }

    &::after {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    @include pc {
      display: block;
      padding: 20px 5%;
      width: 80%;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

.bscBtn01.wNormal {
  max-width: 370px;
  margin: 0 auto;
}

.bscBtn02 a {
  @include bscBtn;
  border: 1px solid #fff;

  &:hover {
    background-color: #fff;
    color: $c01;

    i {
      color: $c01;
    }
  }
}
