@charset "UTF-8";

/* CSS Document */
@media print { 
	body {
	  zoom: 70%;
	}

	.printHidden {
	  display: none;
	}
}