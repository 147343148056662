#footer {
  background-color: $c02;
  color: #000;
  .ftInr {
    max-width: 1000px;
    margin: 0 auto;
    .floatBox {
      display: table;
      padding: 100px 0;
      .logo {
        display: table-cell;
        table-layout: fixed;
        vertical-align: middle;
        width: 12%;
        img {
          width: 100%;
        }
      }
      .address {
        display: table-cell;
        table-layout: fixed;
        vertical-align: middle;
        width: 340px;
        padding: 0 50px;
        border-right: $c04 1px solid;
        .title {
          font-size: 18px;
          font-weight: 800;
          margin-bottom: 20px;
        }
        li {
          margin-bottom: 10px;
          color: $c04;
        }
      }
      .RBox {
        display: table-cell;
        table-layout: fixed;
        vertical-align: middle;
        padding-left: 50px;
        width: 58%;
        .list {
          .title {
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 20px;
            a {
              position: relative;
              &::after {
                position: absolute;
                bottom: -4px;
                left: 0;
                content: "";
                width: 100%;
                height: 1px;
                background: #333;
                transform: scale(0, 1);
                transform-origin: center top;
                transition: transform 0.3s;
              }
              &:hover {
                text-decoration: none;
                &::after {
                  transform: scale(1, 1);
                }
              }
            }
          }
          li {
            float: left;
            font-size: 12px;
            &:nth-child(1) {
              width: 65px;
              margin-right: 40px;
            }
            &:nth-child(2) {
              width: 250px;
              margin-right: 40px;
            }
            &:nth-child(3) {
              width: 120px;
              padding-right: 20px;
            }
          }
          .linkList {
            li {
              width: auto;
              margin: 0;
              padding: 0;
              margin-bottom: 15px;
              a {
                color: $c04;
                position: relative;
                &::after {
                  position: absolute;
                  bottom: -4px;
                  left: 0;
                  content: "";
                  width: 100%;
                  height: 1px;
                  background: $c04;
                  transform: scale(0, 1);
                  transform-origin: center top;
                  transition: transform 0.3s;
                }
                &:hover {
                  text-decoration: none;
                  &::after {
                    transform: scale(1, 1);
                  }
                }
              }
              &.title {
                margin-bottom: 20px;
                a {
                  color: #000;
                }
              }
              &.icon {
                i {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  #copyright {
    background-color: #fff;
    text-align: center;
    padding: 40px 0;
    font-weight: 500;
    @include Montserrat;
  }
}

@include pc {
  #footer {
    .ftInr {
      .floatBox {
        padding: 100px 3%;
        .logo {
          width: 10%;
        }
        .address {
          width: 340px;
          padding: 0 15px;
          .title {
            font-size: 16px;
            margin-bottom: 20px;
          }
          li {
            margin-bottom: 10px;
            color: $c04;
          }
        }
        .RBox {
          padding-left: 15px;
          width: 58%;
          .list {
            .title {
              font-size: 14px;
              margin-bottom: 20px;
            }
            li {
              float: left;
              font-size: 12px;
              &:nth-child(1) {
                width: 60px;
                margin-right: 20px;
              }
              &:nth-child(2) {
                width: 250px;
                margin-right: 20px;
              }
              &:nth-child(3) {
                width: 100%;
                padding-right: 0px;
                margin-top: 20px;
                .title {
                  display: inline-block;
                  width: 20%;
                  margin-bottom: 0;
                }
                .linkList {
                  display: inline-block;
                  width: 70%;
                  li {
                    display: inline-block;
                    margin-bottom: 0;
                    width: 33%;
                  }
                  @include sp {
                    display: block;
                    width: 100%;
                  }
                }
              }
            }
            .linkList {
              li {
                width: auto;
                margin: 0;
                padding: 0;
                margin-bottom: 15px;
                &.title {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }

    #copyright {
      padding: 20px 0;
    }
  }
}

@include tab {
  #footer {
    .ftInr {
      padding: 0 3%;
      .floatBox {
        display: block;
        padding: 40px 0;
        text-align: center;
        .logo {
          display: block;
          width: 12%;
          margin: 0 auto 20px;
          @include sp {
            width: 20%;
          }
        }
        .address {
          display: block;
          width: 100%;
          padding: 0;
          border-right: none;
          margin-bottom: 20px;
          .title {
            font-size: 18px;
            margin-bottom: 20px;
          }
          li {
            margin-bottom: 10px;
          }
        }
        .RBox {
          display: block;
          padding-left: 0px;
          width: 100%;
          .list {
            .title {
              font-size: 14px;
              font-weight: 800;
              margin-bottom: 20px;
            }
            li {
              float: left;
              font-size: 12px;
              &:nth-child(1) {
                width: 35%;
                margin-right: 0px;
              }
              &:nth-child(2) {
                width: 65%;
                margin-right: 0px;
              }
              &:nth-child(3) {
                width: 100%;
                padding-right: 0px;
              }
            }
            .linkList {
              li {
                text-align: center;
                width: 100%;
                margin: 0;
                padding: 0;
                margin-bottom: 15px;
                a {
                  color: $c04;
                }
              }
            }
          }
        }
      }
    }
  }
}
