.editor {
  border: 1px solid $c03;
  padding: 40px 30px;
  @include gothic;
  @include sp {
    padding: 20px 10px;
  }

  img {
    max-width: 100%;
  }

  p {
    line-height: 2;
  }

  .fltImgWrap {
    overflow: hidden;
    margin-bottom: 3em;
  }

  .fltImgL,
  .fltImgR {
    max-width: 45%;
    margin-bottom: 0;

    @include pc {
      max-width: 100%;
      margin-bottom: 2em;
      text-align: center;
    }
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 1.2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
    margin-bottom: 1em;
  }

  ul {
    margin-bottom: 15px;

    li {
      line-height: 170%;
      text-indent: -1em;
      padding-left: 1em;
      margin-bottom: 5px;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        left: 0;
        background-color: #acacac;
        border-radius: 10px;
        margin-right: 0.5em;
      }
    }
  }

  a {
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;
    border-left: 1px solid #dedede;
    border-top: 1px solid #dedede;
    width: 100%;

    th,
    td {
      background-color: #fff;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      line-height: 1.4;
      padding: 20px 20px 17px;
      text-align: left;
    }

    th {
      background-color: #dedede;
      border-bottom: 1px solid #fff;
    }

    tr.head th {
      background-color: $c02;
      color: #000;
    }

    tr:last-child {
      th {
        border-bottom: 1px solid #dedede;
      }
    }
  }
}
